import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import helper from "../../helpers/helpers";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col } from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";
import { Box, IconButton, Tooltip } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CSVLink } from "react-csv";
import "../IncidentReport/IncidentType.scss";

toast.configure();

export default function IncidentType() {
  const history = useHistory();
  const [spinnerMessage] = useState("");
  const [loading] = useState(false);
  const [incidentTypeColumns, setIncidentTypeColumns] = useState([]);
  const [incidentTypeData, setIncidentTypeData] = useState([]);
  const [activeRowId] = useState(null);

  //#region Incident Type Table Data
  const incidentTypeTableData = [
    {
      SlNo: 1,
      IncidentTypeID: "1",
      IncidentType: "ISP - Modem",
      IsActive: "Yes",
    },
    {
      SlNo: 2,
      IncidentTypeID: "2",
      IncidentType: "Network - Network Switches - Wifi adapter",
      IsActive: "No",
    },
    {
      SlNo: 3,
      IncidentTypeID: "3",
      IncidentType: "LAN - Network cable issue",
      IsActive: "Yes",
    },
    {
      SlNo: 4,
      IncidentTypeID: "4",
      IncidentType: "Server - RAID Controller issue - Hard disk issue",
      IsActive: "No",
    },
    {
      SlNo: 5,
      IncidentTypeID: "5",
      IncidentType: "Cyber Security – Firewall - Antivirus - Data loss",
      IsActive: "No",
    },
    {
      SlNo: 6,
      IncidentTypeID: "6",
      IncidentType: "UPS",
      IsActive: "Yes",
    },
    {
      SlNo: 7,
      IncidentTypeID: "7",
      IncidentType: "DG",
      IsActive: "No",
    },
    {
      SlNo: 8,
      IncidentTypeID: "8",
      IncidentType: "Electrical switches / wiring",
      IsActive: "Yes",
    },
    {
      SlNo: 9,
      IncidentTypeID: "9",
      IncidentType: "Security Vulnerability",
      IsActive: "No",
    },
    {
      SlNo: 10,
      IncidentTypeID: "10",
      IncidentType: "Configuration Error",
      IsActive: "Yes",
    },
    {
      SlNo: 11,
      IncidentTypeID: "11",
      IncidentType: "Power Failure",
      IsActive: "Yes",
    },
    {
      SlNo: 12,
      IncidentTypeID: "12",
      IncidentType: "Unauthorized Access",
      IsActive: "No",
    },
    {
      SlNo: 13,
      IncidentTypeID: "13",
      IncidentType: "Firewall Breach",
      IsActive: "No",
    },
    {
      SlNo: 14,
      IncidentTypeID: "14",
      IncidentType: "Malware Attack",
      IsActive: "Yes",
    },
    {
      SlNo: 15,
      IncidentTypeID: "15",
      IncidentType: "Spam Incident",
      IsActive: "No",
    },
    {
      SlNo: 16,
      IncidentTypeID: "16",
      IncidentType: "Service Overload",
      IsActive: "No",
    },
    {
      SlNo: 17,
      IncidentTypeID: "17",
      IncidentType: "Server RAID Issue",
      IsActive: "No",
    },
    {
      SlNo: 18,
      IncidentTypeID: "18",
      IncidentType: "License Expiry",
      IsActive: "No",
    },
    {
      SlNo: 19,
      IncidentTypeID: "19",
      IncidentType: "Compliance Breach",
      IsActive: "Yes",
    },
    {
      SlNo: 20,
      IncidentTypeID: "20",
      IncidentType: "Patch Failure",
      IsActive: "No",
    },
  ];
  //#endregion

  //#region Define table columns and data
  const IncidentTypeTable = () => {
    const incidentTypeColumns = [
      {
        accessorKey: "SlNo",
        header: "Sl No.",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        filterFn: "fuzzy",
      },
      {
        accessorKey: "IncidentTypeID",
        header: "Incident Type ID",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        filterFn: "fuzzy",
      },
      {
        accessorKey: "IncidentType",
        header: "Incident Type",
        muiTableHeadCellProps: {
          align: "center",
          style: {
            width: "100%",
          },
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        filterFn: "fuzzy",
        Cell: ({ row }) => {
          const { IncidentType } = row.original;
          return (
            <span
              style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
              onClick={moveToViewIncidentType}
            >
              {`${IncidentType}`}
            </span>
          );
        },
      },
      {
        accessorKey: "IsActive",
        header: "Is Active",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        filterFn: "fuzzy",
      }
    ];
    return { incidentTypeColumns, incidentTypeTableData };
  };
  //#endregion

  //#region Initialize table columns and data on mount
  useEffect(() => {
    const { incidentTypeColumns, incidentTypeTableData } = IncidentTypeTable();
    setIncidentTypeColumns(incidentTypeColumns);
    setIncidentTypeData(incidentTypeTableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion

  //#region Handle view modifier template click
  const moveToViewIncidentType = () => {
    history.push('/ViewIncidentType');
  };
  //#endregion

  //#region CSV export handler
  const handleIncidentTypeCSVExport = () => {
    if (csvLink) {
      csvLink.link.click();
    }
  };
  //#endregion

  //#region Get transformed noun modifier data for CSV export
  const getTransformedIncidentTypeDataForExport = () => {
    return incidentTypeData;
  };
  //#endregion

  //#region Navigate Add Incident Type Page
  const moveToCreateIncidentType = () => {
    history.push('/AddIncidentType');
  };
  //#endregion

  let csvLink;

  //#region return
  return (
    <div>
      <LoadingOverlay
        active={loading}
        className="custom-loader"
        spinner={
          <div className="spinner-background">
            <BarLoader css={helper.getcss()} color={"#38D643"} width={"350px"} height={"10px"} speedMultiplier={0.3}/>
            <p style={{ color: "black", marginTop: "5px" }}>{spinnerMessage}</p>
          </div>
        }
      >
        <Row className="mg-t-5 mg-l-25 mg-r-15">
          <div className="incidentTypeMainContent">
            <h4>
              Incident Types List{" "}
              <span className="icon-size mg-l-5">
                <i className="fa fa-plus text-primary pointer" onClick={moveToCreateIncidentType} title="Create Incident Type"></i>
              </span>
            </h4>
          </div>
        </Row>
        <Row className="mg-t-5 mg-l-10 mg-r-15">
          <Col lg={12} style={{ maxWidth: "100%" }}>
            <div style={{ border: "1px solid #cdd4e0" }} className="mg-l-0 mg-r-0 mg-t-0 incidentTypeMainText">
              <div className="col-md-12 pd-t-10 pd-b-10 ">
                <div className="mg-t-0">
                  <div className="masters-material-table incidentTypeTable">
                    <MaterialReactTable
                      data={incidentTypeData}
                      columns={incidentTypeColumns}
                      muiPaginationProps={{
                        color: "primary",
                        shape: "rounded",
                        showRowsPerPage: false,
                        variant: "outlined",
                        sx: {
                          "& .MuiPaginationItem-root": {
                            borderColor: "#5B47FB",
                          },
                          "& .Mui-selected": {
                            backgroundColor: "#5B47FB",
                            color: "white",
                          },
                          "& .MuiPaginationItem-ellipsis": {
                            borderColor: "#5B47FB",
                          },
                          marginTop: "16px",
                        },
                      }}
                      paginationDisplayMode="pages"
                      enableColumnFilterModes={false}
                      enableColumnOrdering={false}
                      enableStickyHeader={true}
                      enableDensityToggle={true}
                      enableGlobalFilter={true}
                      enableRowSelection={false}
                      initialState={{ density: "compact", pagination: { pageIndex: 0, pageSize: 100 } }}
                      getRowProps={(row) => ({
                        style: {backgroundColor: activeRowId === row.original.id ? "#e0e0e0" : "transparent"},
                      })}
                      renderTopToolbarCustomActions={() => (
                        <Box sx={{display: "flex", gap: "16px", padding: "8px", flexWrap: "wrap"}}>
                          <Tooltip title="Download CSV">
                            <IconButton onClick={handleIncidentTypeCSVExport}>
                              <FileDownloadIcon
                                title="Export to CSV"
                                style={{color: "#5B47FB", width: "1em", height: "1em"}}
                              />
                            </IconButton>
                          </Tooltip>
                          <CSVLink
                            data={getTransformedIncidentTypeDataForExport()}
                            headers={[
                              { label: "Sl No.", key: "SlNo" },
                              { label: "IncidentType ID", key: "IncidentTypeID" },
                              { label: "Incident Type", key: "IncidentType" },
                              { label: "Is Active", key: "IsActive" },
                            ]}
                            filename="Incident Type Data.csv"
                            ref={(r) => (csvLink = r)}
                            target="_blank"
                            style={{ display: "none" }}
                          />
                        </Box>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </LoadingOverlay>
    </div>
  );
  //#endregion
}