import React, { useState, useEffect } from "react";
import helper from "../../helpers/helpers";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../MRODictionary/MRODictionaryNew.scss";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { MaterialReactTable } from "material-react-table";
import { Button, Modal } from "react-bootstrap";
import { Box, IconButton, Tooltip } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CSVLink } from "react-csv";
import ReportIncident from "./RegisterIncident";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import "../IncidentReport/IncidentReport.scss";

toast.configure();

function IncidentReport(props) {
  const [spinnerMessage] = useState("");
  const [loading] = useState(false);
  const [incidentReportColumns, setIncidentTypeColumns] = useState([]);
  const [incidentReportData, setIncidentTypeData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedIncident, setSelectedIncident] = useState(null);
  const [activeRowId] = useState(null);
  const handleIncidentClick = (incident) => {
    setSelectedIncident(incident);
    setShowModal(true);
  };
  const {
    showIncidentReportColumns = true,
    showIncidentReport = true,
    incidentReportDashboardData = true,
    showPagination = true,
  } = props;

  //#region Handle Edit Incident
  const handleEditIncident = (incident) => {
    console.log("Editing incident:", incident);
  };
  //#endregion

  //#region Handle Edit Action
  const handleEditAction = (incident) => {
    // Your edit action logic here
    console.log("Editing action on incident:", incident);
  };
  //#endregion

  //#region Handle Delete Incident
  const handleDeleteIncident = (incident) => {
    console.log("Deleting incident:", incident);
  };
  //#endregion

  //#region Incident Report Table Data
  const incidentReportTableData = [
    {
      EditIncident: "",
      EditActionOnIncident: "",
      DeleteIncident: "",
      IncidentNo: "INC001",
      IncidentType: "Power Outage",
      Department: "Admin / IT",
      Description: "There was an issue with the Power switch in the server rack, hence all the servers were shut down forcibly due to power failure",
      InsidentStatus:"InProgress",
      ReporterName: "Naveen",
      ContactNo: "1234567890",
      Email: "nveen@example.com",
      IncidentDate: "2023-10-28",
      IncidentTime: "10:00 AM",
      Location: "Server Room",
      InformationAffected: "",
      EquipmentAffected: "Server",
      PeopleAffected: "Nil",
      BusinessImpact: "Low",
      Priority: "High",
      DepartmentsAffected: "Admin / IT",
      AssetIDs: "ASSET-1234",
    },
    {
      EditIncident: "",
      EditActionOnIncident: "",
      DeleteIncident: "",
      IncidentNo: "INC002",
      IncidentType: "QNAP HDD failure",
      Department: "IT",
      Description: "There was an failure of QNAP HDD Raid",
      InsidentStatus:"Completed",
      ReporterName: "Naveen",
      ContactNo: "9876543210",
      Email: "nveen@example.com",
      IncidentDate: "2023-10-29",
      IncidentTime: "02:00 PM",
      Location: "Server Room",
      InformationAffected: "IS backup data",
      EquipmentAffected: "QNAP Server",
      PeopleAffected: "Nil",
      BusinessImpact: "High",
      Priority: "Critical",
      DepartmentsAffected: "IT",
      AssetIDs: "ASSET-5678",
    },
    {
      EditIncident: "",
      EditActionOnIncident: "",
      DeleteIncident: "",
      IncidentNo: "INC003",
      IncidentType: "Software Issue",
      Department: "IT/Production",
      Description: "Software crash during update",
      InsidentStatus:"InProgress",
      ReporterName: "Alice Brown",
      ContactNo: "1231231234",
      Email: "nveen@example.com",
      IncidentDate: "2023-10-27",
      IncidentTime: "11:30 AM",
      Location: "Office 203",
      InformationAffected: "Data Integrity",
      EquipmentAffected: "Computer",
      PeopleAffected: 2,
      BusinessImpact: "Low",
      Priority: "Medium",
      DepartmentsAffected: "IT/Production",
      AssetIDs: "ASSET-2345",
    },
    {
      EditIncident: "",
      EditActionOnIncident: "",
      DeleteIncident: "",
      IncidentNo: "INC004",
      IncidentType: "Internet Line down",
      Department: "IT/Production",
      Description: "There was an issue with the Tata ISP on one of the lines, where line outage for 24 hours",
      InsidentStatus:"Pending",
      ReporterName: "Naveen",
      ContactNo: "4564564567",
      Email: "nveen@example.com",
      IncidentDate: "2023-10-26",
      IncidentTime: "09:15 AM",
      Location: "Server Room",
      InformationAffected: "Browsing",
      EquipmentAffected: "Fiber cut",
      PeopleAffected: 1,
      BusinessImpact: "Low",
      Priority: "Low",
      DepartmentsAffected: "IT/Production",
      AssetIDs: "ASSET-6789",
    },
    {
      EditIncident: "",
      EditActionOnIncident: "",
      DeleteIncident: "",
      IncidentNo: "INC005",
      IncidentType: "Internet Line down – Primary and Backup",
      Department: "IT/Production",
      Description: "There was an issue with the Tata and Metronet ISP both lines were down, where line outage for 5 hours",
      InsidentStatus:"InProgress",
      ReporterName: "Naveen",
      ContactNo: "7897897890",
      Email: "nveen@example.com",
      IncidentDate: "2023-10-25",
      IncidentTime: "08:45 AM",
      Location: "Server Room",
      InformationAffected: "Browsing",
      EquipmentAffected: "Fiber cut",
      PeopleAffected: "All Users",
      BusinessImpact: "High",
      Priority: "High",
      DepartmentsAffected: "IT/Production",
      AssetIDs: "ASSET-3456",
    },
    {
      EditIncident: "",
      EditActionOnIncident: "",
      DeleteIncident: "",
      IncidentNo: "INC006",
      IncidentType: "Internet Line down",
      Department: "IT/Production",
      Description: "There was an issue with both Tata & metronet ISP ",
      InsidentStatus:"Completed",
      ReporterName: "Naveen",
      ContactNo: "2342342345",
      Email: "nveen@example.com",
      IncidentDate: "2023-10-24",
      IncidentTime: "05:00 PM",
      Location: "Server Room",
      InformationAffected: "Browsing",
      EquipmentAffected: "Fiber cut",
      PeopleAffected: "Nil",
      BusinessImpact: "Low",
      Priority: "Critical",
      DepartmentsAffected: "IT/Production",
      AssetIDs: "ASSET-7890",
    },
    {
      EditIncident: "",
      EditActionOnIncident: "",
      DeleteIncident: "",
      IncidentNo: "INC007",
      IncidentType: "Internet Line down",
      Department: "IT/Production",
      Description: "There was an Ransomeware Virus attack to Galaxy-server. All the data files was locked. ",
      InsidentStatus:"InProgress",
      ReporterName: "Naveen",
      ContactNo: "2342342345",
      Email: "nveen@example.com",
      IncidentDate: "2023-08-22",
      IncidentTime: "02:30 PM",
      Location: "Server Room",
      InformationAffected: "Browsing",
      EquipmentAffected: "Fiber cut",
      PeopleAffected: "Nil",
      BusinessImpact: "Low",
      Priority: "Critical",
      DepartmentsAffected: "IT/Production",
      AssetIDs: "ASSET-3590",
    },
    {
      EditIncident: "",
      EditActionOnIncident: "",
      DeleteIncident: "",
      IncidentNo: "INC008",
      IncidentType: "Server Down",
      Department: "IT/Production",
      Description: "There was a MCB trip on the Server Power supply due to Voltage issue. MCB rectified ",
      InsidentStatus:"InProgress",
      ReporterName: "Naveen",
      ContactNo: "2342342345",
      Email: "nveen@example.com",
      IncidentDate: "2023-05-24",
      IncidentTime: "01:30 PM",
      Location: "Server Room",
      InformationAffected: "Server Access",
      EquipmentAffected: "Server MCB",
      PeopleAffected: "Nil",
      BusinessImpact: "High",
      Priority: "Critical",
      DepartmentsAffected: "IT/Production",
      AssetIDs: "ASSET-2690",
    },
    {
      EditIncident: "",
      EditActionOnIncident: "",
      DeleteIncident: "",
      IncidentNo: "INC009",
      IncidentType: "Server Down",
      Department: "IT/Production",
      Description: "There was a MCB trip on the Server Power supply due to Voltage issue. MCB rectified ",
      InsidentStatus:"Pending",
      ReporterName: "Naveen",
      ContactNo: "2342342345",
      Email: "nveen@example.com",
      IncidentDate: "2023-09-24",
      IncidentTime: "02:30 PM",
      Location: "Server Room",
      InformationAffected: "Server Access",
      EquipmentAffected: "Server",
      PeopleAffected: "Nil",
      BusinessImpact: "High",
      Priority: "Critical",
      DepartmentsAffected: "IT/Production",
      AssetIDs: "ASSET-1989",
    },
    {
      EditIncident: "",
      EditActionOnIncident: "",
      DeleteIncident: "",
      IncidentNo: "INC010",
      IncidentType: "Netwrok Switch Issue",
      Department: "IT/Production",
      Description: "There was power supply disruption, Switch overloaded and crashed",
      InsidentStatus:"InProgress",
      ReporterName: "Naveen",
      ContactNo: "2342342345",
      Email: "nveen@example.com",
      IncidentDate: "2023-09-24",
      IncidentTime: "02:30 PM",
      Location: "First Floor",
      InformationAffected: "Network Access",
      EquipmentAffected: "Network Switch",
      PeopleAffected: "Nil",
      BusinessImpact: "Low",
      Priority: "Low",
      DepartmentsAffected: "IT/Production",
      AssetIDs: "ASSET-3390",
    },
  ];
  //#endregion

  //#region Close The modal
  const handleClose = () => {
    setShowModal(false);
    setSelectedIncident(null);
  };
  //#endregion

  //#region Incident Report Columns 
  const incidentReportColumnsConfig = [
    ...(showIncidentReportColumns
      ? [
        {
          accessorKey: "EditIncident",
          header: "Edit Incident",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "center" },
          enableColumnFilterModes: false,
          enableSorting: false,
          size:100,
          enableColumnActions: false,
          Cell: ({ row }) => (
            <IconButton
              onClick={() => handleEditIncident(row.original)}
              sx={{ color: "#000000" }}
              className="editIncidentIcon"
            >
              <EditIcon />
            </IconButton>
          ),
        },
        {
          accessorKey: "EditActionOnIncident",
          header: "Edit Action On Incident",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "center" },
          enableColumnFilterModes: false,
          enableSorting: false,
          size:100,
          enableColumnActions: false,
          Cell: ({ row }) => (
            <IconButton
              onClick={() => handleEditAction(row.original)}
              sx={{ color: "#5b47fb" }}
              className="editActionOnIncidentIcon"
            >
              <EditIcon />
            </IconButton>
          ),
        },
        {
          accessorKey: "DeleteIncident",
          header: "Delete Incident",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "center" },
          enableColumnFilterModes: false,
          enableSorting: false,
          size:100,
          enableColumnActions: false,
          Cell: ({ row }) => (
            <IconButton
              onClick={() => handleDeleteIncident(row.original)}
              sx={{ color: "red" }}
              className="deleteIncidentIcon"
            >
              <DeleteIcon />
            </IconButton>
          ),
        },
      ]
      : []),
    ...(incidentReportDashboardData
      ? [
        {
          accessorKey: "IncidentNo",
          header: "Incident No",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "center" },
          size:100,
          Cell: ({ row }) => (
            <span
              onClick={() => handleIncidentClick(row.original)}
              style={{ cursor: "pointer", color: "#007bff", textDecoration: "underline" }}
            >
              {row.original.IncidentNo}
            </span>
          ),
        },
        {
          accessorKey: "IncidentType",
          header: "Incident Type",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "left" },
        },
        {
          accessorKey: "Department",
          header: "Department Resolving Incident",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "center" },
        },
        {
          accessorKey: "Description",
          header: "Description of Incident",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "left" },
        },
        {
          accessorKey: "InsidentStatus",
          header: "Insident Status",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "center" },
          size:100,
        },
        {
          accessorKey: "ReporterName",
          header: "Name of the Person Reporting Incident",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "center" },
        },
        {
          accessorKey: "ContactNo",
          header: "Contact No.",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "center" },
          size:100,
        },
        {
          accessorKey: "Email",
          header: "Email",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "center" },
        },
        {
          accessorKey: "IncidentDate",
          header: "Date of Incident",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "center" },
          size:100,
        },
        {
          accessorKey: "IncidentTime",
          header: "Time of Incident",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "center" },
          size:100,
        },
        {
          accessorKey: "Location",
          header: "Location of Incident",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "center" },
        },
        {
          accessorKey: "InformationAffected",
          header: "Information Affected",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "center" },
        },
        {
          accessorKey: "EquipmentAffected",
          header: "Equipment Affected",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "center" },
        },
        {
          accessorKey: "PeopleAffected",
          header: "Number of People Affected",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "center" },
        },
        {
          accessorKey: "BusinessImpact",
          header: "Impact on Business",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "center" },
        },
        {
          accessorKey: "Priority",
          header: "Priority",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "center" },
          size:100,
        },
        {
          accessorKey: "DepartmentsAffected",
          header: "Departments Affected",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "center" },
        },
        {
          accessorKey: "AssetIDs",
          header: "Asset IDs",
          muiTableHeadCellProps: { align: "center" },
          muiTableBodyCellProps: { align: "center" },
          size:100,
        },
      ]
      : []),
  ];
  //#endregion

  //#region UseEffect
  useEffect(() => {
    setIncidentTypeColumns(incidentReportColumnsConfig);
    setIncidentTypeData(incidentReportTableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion

  //#region CSV export handler
  const handleIncidentReportCSVExport = () => {
    if (csvLink) {
      csvLink.link.click();
    }
  };
  //#endregion

  //#region Get transformed noun modifier data for CSV export
  const getTransformedIncidentReportDataForExport = () => {
    return incidentReportData;
  };
  //#endregion

  let csvLink;

  //#region Search Options
  const [searchOn, setSearchOn] = useState('');
  const searchOptions = {
    incidentNo: ["INC123", "INC456", "INC789", "INC101"],
    incidentType: ["High Priority", "Moderate Priority", "Low Priority"],
    department: ["Finance", "HR", "IT"],
    reporterName: ["John Doe", "Jane Smith", "Mike Johnson", "Emily Davis"],
    priority: ["High Priority", "Moderate Priority", "Low Priority"],
    status: ["Pending", "InProgress", "Completed", "Resolved", "Unresolved"],
  };
  //#endregion

  //#region Search On Change
  const handleSearchOnChange = (event) => {
    setSearchOn(event.target.value);
  };
  //#endregion

  //#region return
  return (
    <div>
      <LoadingOverlay
        active={loading}
        className="custom-loader"
        spinner={
          <div className="spinner-background">
            <BarLoader css={helper.getcss()} color={"#38D643"} width={"350px"} height={"10px"} speedMultiplier={0.3}/>
            <p style={{ color: "black", marginTop: "5px" }}>{spinnerMessage}</p>
          </div>
        }
      >
        {showIncidentReport && (
          <div className="row mg-r-15 mg-l-10 mg-t-10">
            <div className="col-lg-4">
              <div className="incidentReportData">
                <FloatingLabel label="Search On" className="float-hidden float-select">
                  <select className="form-control" id="searchOn" name="searchOn" value={searchOn} onChange={handleSearchOnChange}>
                    <option value="">---Select fields---</option>
                    <option value="incidentNo">Incident No</option>
                    <option value="incidentType">Incident Type</option>
                    <option value="department">Department</option>
                    <option value="reporterName">Reporter Name</option>
                    <option value="priority">Priority</option>
                    <option value="status">Status</option>
                  </select>
                </FloatingLabel>
                <span className="text-danger asterisk-size ml-2">*</span>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="incidentReportData">
                <FloatingLabel label="Search" className="float-hidden float-select">
                  <select className="form-control" id="search" name="search">
                    <option value="">---Select fields---</option>
                    {searchOptions[searchOn]?.map((option, index) => (
                      <option key={index} value={option.toLowerCase()}>{option}</option>
                    ))}
                  </select>
                </FloatingLabel>
                <span className="text-danger asterisk-size ml-2">*</span>
              </div>
            </div>
            <div className="col-lg-4">
              <Button variant="secondary" className="incidentReportBtn">
                <i className="fa fa-search"></i> Search
              </Button>
            </div>
          </div>
        )}
        <div style={{ border: "1px solid #cdd4e0" }} className="mg-l-25 mg-r-20 mg-t-15 mg-b-15 incidentReportMainText">
          <div className="row mg-r-0 mg-l-0 mg-t-10 mg-b-15">
            <div className="col-lg-12">
              <div className="masters-material-table incidentReportTableContent">
                <MaterialReactTable
                  data={incidentReportData}
                  columns={incidentReportColumns}
                  initialState={{
                    density: "compact",
                    pagination: { pageIndex: 0, pageSize: 100 },
                  }}
                  muiPaginationProps={{
                    color: "primary",
                    shape: "rounded",
                    showRowsPerPage: false,
                    variant: "outlined",
                    sx: {
                      "& .MuiPaginationItem-root": {
                        borderColor: "#5B47FB",
                      },
                      "& .Mui-selected": {
                        backgroundColor: "#5B47FB",
                        color: "white",
                      },
                      "& .MuiPaginationItem-ellipsis": {
                        borderColor: "#5B47FB",
                      },
                      marginTop: "16px",
                    },
                  }}

                  paginationDisplayMode={showPagination ? "pages" : false}
                  enableColumnFilterModes
                  enableColumnOrdering={false}
                  enableStickyHeader={true}
                  enableDensityToggle={true}
                  enableGlobalFilter={true}
                  enableRowSelection={false}
                  getRowProps={(row) => ({
                    style: {
                      backgroundColor: activeRowId === row.original.id ? "#e0e0e0" : "transparent",
                    },
                  })}
                  renderTopToolbarCustomActions={() => (
                    <Box sx={{display: "flex", gap: "16px", padding: "8px", flexWrap: "wrap"}}>
                      <Tooltip title="Download CSV">
                        <IconButton onClick={handleIncidentReportCSVExport}>
                          <FileDownloadIcon title="Export to CSV" style={{color: "#5B47FB", width: "1em", height: "1em"}}/>
                        </IconButton>
                      </Tooltip>
                      <CSVLink
                        data={getTransformedIncidentReportDataForExport()}
                        headers={[
                          { label: "Incident No", key: "IncidentNo" },
                          { label: "Incident Type", key: "IncidentType" },
                          { label: "Department", key: "Department" },
                          { label: "Reporter Name", key: "ReporterName" },
                          { label: "Contact No", key: "ContactNo" },
                          { label: "Email", key: "Email" },
                          { label: "Insident Status", key: "InsidentStatus" },
                          { label: "Incident Date", key: "IncidentDate" },
                          { label: "Incident Time", key: "IncidentTime" },
                          { label: "Location", key: "Location" },
                          { label: "Information Affected", key: "InformationAffected" },
                          { label: "Equipment Affected", key: "EquipmentAffected" },
                          { label: "People Affected", key: "PeopleAffected" },
                          { label: "Business Impact", key: "BusinessImpact" },
                          { label: "Priority", key: "Priority" },
                          { label: "Departments Affected", key: "DepartmentsAffected" },
                          { label: "AssetIDs", key: "AssetIDs" },
                        ]}
                        filename="Incident Report Data.csv"
                        ref={(r) => (csvLink = r)}
                        target="_blank"
                        style={{ display: "none" }}
                      />
                    </Box>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" centered className="incidentReportModal">
        <Modal.Header closeButton>
          <Modal.Title>Incident Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReportIncident incidentData={selectedIncident} showButtons={false} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
  //#endregion
}
export default IncidentReport;