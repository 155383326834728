import React, { useState } from "react";
import helpers from "../../helpers/helpers";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { BarLoader } from "react-spinners";
import { Button, Modal } from "react-bootstrap";

toast.configure();

export default function ViewIncidentType() {
  let history = useHistory();
  //#region State initialization
  const [initStates, setInitStates] = useState({
    activityID: 0,
    activity: "",
    isActive: true,
    formErrors: "",
    loading: false,
    spinnerMessage: "",
    showViewIncidentTypeDeleteModal: false,
  });
  //#endregion

  //#region Navigate to Edit Incident Type 
  const editIncidentType = () => {
    history.push('/EditIncidentType'); 
  };
  //#endregion

  //#region Handle View Incident Type Delete Confirmation
  const handleViewIncidentTypeDeleteConfirmation = () => {
    setInitStates((prevState) => ({
      ...prevState,
      showViewIncidentTypeDeleteModal: true,
    }));
  };
  //#endregion
 
  //#region Handle View Incident Type Delete Yes
  const handleViewIncidentTypeDeleteYes = () => {
    handleViewIncidentTypeDeleteNo();
  };
  //#endregion

  //#region Handle View Incident Type Delete No
  const handleViewIncidentTypeDeleteNo = () => {
    setInitStates((prevState) => ({
      ...prevState,
      showViewIncidentTypeDeleteModal: false,
    }));
  };
  //#endregion

  //#region Return
  return (
    <div className="pro-main-display">
      <LoadingOverlay
        active={initStates.loading}
        className="custom-loader"
        spinner={
          <div className="spinner-background">
            <BarLoader css={helpers.getcss()} color={"#38D643"} width={"350px"} height={"10px"} speedMultiplier={0.3}/>
            <p style={{ color: "black", marginTop: "5px" }}>{initStates.spinnerMessage}</p>
          </div>
        }
      >
        <div className="incidentTypeMainContent" style={{ height: "100%", position: "relative", paddingLeft: "20px", marginTop: "30px"}}>
          <h4>
            View Incident Type{" "}
            <span className="icon-size mg-l-5">
              <i className="far fa-arrow-alt-circle-left text-primary pointer" onClick={() => history.goBack()} title="Back to List"></i>
            </span>
          </h4>
          <div>
            <div className="row row-sm mg-t-20 incidentTypeText">
              <div className="col-lg-2">
                <div className="incidentTypeContent">
                  <label>Incident Type ID </label>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="incidentTypeContent">
                  <span>001</span>
                </div>
              </div>
            </div>
            <div className="row row-sm mg-t-20 incidentTypeText">
              <div className="col-lg-2">
                <div className="incidentTypeContent">
                  <label>Incident Type </label>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="incidentTypeContent">
                  <span>System</span>
                </div>
              </div>
            </div>
            <br />
            <div className="row row-sm incidentTypeText">
              <div className="col-lg-2">
                <div className="incidentTypeContent">
                  <label>Is Active? </label>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="incidentTypeContent">
                  <span>Yes</span>
                </div>
              </div>
            </div>
            <br />
            <div className="row row-sm">
              <div className="col-md-2">
                <button id="Save" className="btn btn-gray-700 btn-block" tabIndex="4" onClick={editIncidentType}>
                  <i className="fa fa-pencil"></i> Edit
                </button>
              </div>
              <div className="col-md-2">
                <button className="btn btn-gray-700 btn-block" tabIndex="5" onClick={handleViewIncidentTypeDeleteConfirmation} id="Reset">
                  <i className="fa fa-close"></i> Delete
                </button>
              </div>
            </div>
            <br />
          </div>
        </div>
        <Modal className="updateProjectDetailsDeleteConfirmationModal" show={initStates.showViewIncidentTypeDeleteModal} onHide={handleViewIncidentTypeDeleteNo} aria-labelledby="contained-modal-title-vcenter" backdrop="static" enforceFocus={false}>
          <Modal.Header>
            <Modal.Title>Delete Incident Type Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>Are you sure, you want to delete this Incident Type?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleViewIncidentTypeDeleteYes}>Yes</Button>
            <Button variant="primary" onClick={handleViewIncidentTypeDeleteNo}>No</Button>
          </Modal.Footer>
        </Modal>
      </LoadingOverlay>
    </div>
  );
  //#endregion
}