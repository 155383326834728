import React, { useState, useEffect } from "react";
import helper from "../../helpers/helpers";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { MaterialReactTable } from "material-react-table";
import { Box, IconButton, Tooltip } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CSVLink } from "react-csv";
import IncidentReport from "../IncidentReport/IncidentReport";
import "../IncidentReport/IncidentReportDashboard.scss";

toast.configure();

function IncidentReportDashboard() {
  const [spinnerMessage] = useState("");
  const [loading] = useState(false);
  const [incidentReportDashboardColumns, setIncidentTypeColumns] = useState([]);
  const [incidentReportDashboardTypeData, setIncidentTypeData] = useState([]);
  const [activeRowId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalData, setModalData] = useState('');
  const [showPagination, setShowPagination] = useState(false);
  const [pendingIncidents, setPendingIncidents] = useState(false);

  //#region Modal Open
  const handleModalOpen = (title, type) => {
    const filteredData = incidentReportDashboardTypeData.filter(item =>
      (type === 'Pending' && item.PendingIncidents > 0) ||
      (type === 'In Progress' && item.InProgressIncidents > 0) ||
      (type === 'Completed' && item.Completed > 0) ||
      (type === 'Total' && item.Total > 0)
    );

    setModalTitle(title);
    setModalData(filteredData);
    setPendingIncidents(type === 'Pending');
    setShowPagination(type !== 'Pending');
    setModalOpen(true);
  };
  //#endregion

  //#region Modal Close
  const handleModalClose = () => {
    setModalOpen(false);
    setModalTitle('');
    setModalData('');
  };
  //#endregion

  //#region Incident Report Dashboard table data
  const IncidentReportDashboardTableData = [
    {
      Department: 'Admin/IT',
      IncidentType: 'Power Outage',
      PendingIncidents: 2,
      InProgressIncidents: 1,
      Completed: 5,
      Total: 8,
      incidents: [
        { id: 1, description: 'Incident 1 Description', status: 'Pending' },
        { id: 2, description: 'Incident 2 Description', status: 'Pending' },
      ],
    },
    {
      Department: 'IT',
      IncidentType: 'QNAP HDD failure',
      PendingIncidents: 1,
      InProgressIncidents: 0,
      Completed: 3,
      Total: 4,
      incidents: [
        { id: 3, description: 'Incident 3 Description', status: 'Pending' },
      ],
    },
    {
      Department: 'IT/Production',
      IncidentType: 'Software Issue',
      PendingIncidents: 0,
      InProgressIncidents: 2,
      Completed: 7,
      Total: 9,
    },
    {
      Department: 'IT/Production',
      IncidentType: 'Internet Line down',
      PendingIncidents: 1,
      InProgressIncidents: 2,
      Completed: 1,
      Total: 4,
    },
    {
      Department: 'IT/Production',
      IncidentType: 'Internet Line down – Primary and Backup',
      PendingIncidents: 1,
      InProgressIncidents: 2,
      Completed: 1,
      Total: 4,
    },
    {
      Department: 'IT/Production',
      IncidentType: 'Internet Line down',
      PendingIncidents: 1,
      InProgressIncidents: 0,
      Completed: 3,
      Total: 4,
      incidents: [
        { id: 3, description: 'Incident 3 Description', status: 'Pending' },
      ],
    },
    {
      Department: 'IT/Production',
      IncidentType: 'Server Down',
      PendingIncidents: 2,
      InProgressIncidents: 1,
      Completed: 5,
      Total: 8,
      incidents: [
        { id: 1, description: 'Incident 1 Description', status: 'Pending' },
        { id: 2, description: 'Incident 2 Description', status: 'Pending' },
      ],
    },
    {
      Department: 'IT/Production',
      IncidentType: 'Netwrok Switch Issue',
      PendingIncidents: 0,
      InProgressIncidents: 2,
      Completed: 7,
      Total: 9,
    },
   
  ];
  //#endregion

  //#region Define table columns and data
  const IncidentReportDashboardTypeTable = () => {
    const incidentReportDashboardColumns = [
      {
        accessorKey: "Department",
        header: "Department",
        size: 100, 
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "IncidentType",
        header: "Incident Type",
        muiTableHeadCellProps: {
          align: "center",
          style: {
            width: "100%",
          },
        },
        muiTableBodyCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "PendingIncidents",
        header: "Pending Incidents",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => (
          <span
            className="pending-incidents"
            style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
            onClick={() => handleModalOpen(
              'Pending Incidents',
              'Pending',
              row.original.incidents
            )}
          >
            {row.original.PendingIncidents}
          </span>
        ),
      },
      {
        accessorKey: "InProgressIncidents",
        header: "In Progress Incidents",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => (
          <span
            className="inProgress-incidents"
            style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
            onClick={() => handleModalOpen(
              'In Progress Incidents',
              {
                type: 'In Progress',
                count: row.original.InProgressIncidents,
                details: 'Details for in-progress incidents here...'
              }
            )}
          >
            {row.original.InProgressIncidents}
          </span>
        ),
      },
      {
        accessorKey: "CompletedIncidents",
        header: "Completed Incidents",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => (
          <span
            className="completed-incidents"
            style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
            onClick={() => handleModalOpen(
              'Completed Incidents',
              {
                type: 'Completed',
                count: row.original.Completed,
                details: 'Details for completed incidents here...'
              }
            )}
          >
            {row.original.Completed}
          </span>
        ),
      },
      {
        accessorKey: "Total",
        header: "Total",
        size: 100, 
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ row }) => (
          <span
            className="total-incidents"
            style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
            onClick={() => handleModalOpen(
              'Total Incidents',
              {
                type: 'Total Incidents',
                count: row.original.Total,
                details: 'Details for total incidents here...'
              }
            )}
          >
            {row.original.Total}
          </span>
        ),
      },
    ];

    return { incidentReportDashboardColumns, IncidentReportDashboardTableData };
  };
  //#endregion

  //#region Initialize table columns and data on mount
  useEffect(() => {
    const { incidentReportDashboardColumns, IncidentReportDashboardTableData } = IncidentReportDashboardTypeTable();
    setIncidentTypeColumns(incidentReportDashboardColumns);
    setIncidentTypeData(IncidentReportDashboardTableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion

  //#region CSV export handler
  const handleIncidentReportDashboardCSVExport = () => {
    if (csvLink) {
      csvLink.link.click();
    }
  };
  //#endregion

  //#region Get transformed noun modifier data for CSV export
  const getTransformedIncidentReportDashboardDataForExport = () => {
    return incidentReportDashboardTypeData;
  };
  //#endregion
  let csvLink;
  //#region return
  return (
    <div>
      <LoadingOverlay
        active={loading}
        className="custom-loader"
        spinner={
          <div className="spinner-background">
            <BarLoader css={helper.getcss()} color={"#38D643"} width={"350px"} height={"10px"} speedMultiplier={0.3}/>
            <p style={{ color: "black", marginTop: "5px" }}>{spinnerMessage}</p>
          </div>
        }
      >
        <Row className="mg-t-5 mg-l-10 mg-r-15" style={{ justifyContent: 'center' }} >
          <Col lg={10}>
            <div style={{ border: "1px solid #cdd4e0" }} className="mg-l-0 mg-r-0 mg-t-0 incidentTypeMainText">
              <div className="col-md-12 pd-t-10 pd-b-10 ">
                <div className="mg-t-0">
                  <div className="masters-material-table incidentReportDashboardTypeTableContent">
                    <MaterialReactTable
                      data={incidentReportDashboardTypeData}
                      columns={incidentReportDashboardColumns}
                      muiPaginationProps={{
                        color: "primary",
                        shape: "rounded",
                        showRowsPerPage: false,
                        variant: "outlined",
                        sx: {
                          "& .MuiPaginationItem-root": {
                            borderColor: "#5B47FB",
                          },
                          "& .Mui-selected": {
                            backgroundColor: "#5B47FB",
                            color: "white",
                          },
                          "& .MuiPaginationItem-ellipsis": {
                            borderColor: "#5B47FB",
                          },
                          marginTop: "16px",
                        },
                      }}
                      paginationDisplayMode="pages"
                      enableColumnFilterModes={true}
                      enableColumnOrdering={false}
                      enableStickyHeader={true}
                      enableDensityToggle={true}
                      enableGlobalFilter={true}
                      enableRowSelection={false}
                      enablePagination={false}
                      initialState={{ density: "compact", pagination: { pageIndex: 0, pageSize: 100 } }}
                      getRowProps={(row) => ({
                        style: {
                          backgroundColor: activeRowId === row.original.id ? "#e0e0e0" : "transparent",
                        },
                      })}
                      renderTopToolbarCustomActions={() => (
                        <Box sx={{display: "flex", gap: "16px", padding: "8px", flexWrap: "wrap"}}>
                          <Tooltip title="Download CSV">
                            <IconButton onClick={handleIncidentReportDashboardCSVExport}>
                              <FileDownloadIcon
                                title="Export to CSV"
                                style={{color: "#5B47FB", width: "1em", height: "1em"}}
                              />
                            </IconButton>
                          </Tooltip>
                          <CSVLink
                            data={getTransformedIncidentReportDashboardDataForExport()}
                            headers={[
                              { label: "Department", key: "Department" },
                              { label: "Incident Type", key: "IncidentType" },
                              { label: "Pending Incidents", key: "PendingIncidents" },
                              { label: "InProgress Incidents", key: "InProgressIncidents" },
                              { label: "Completed", key: "Completed" },
                              { label: "Total", key: "Total" },
                            ]}
                            filename="Incident Report Dashboard Data.csv"
                            ref={(r) => (csvLink = r)}
                            target="_blank"
                            style={{ display: "none" }}
                          />
                        </Box>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Modal show={modalOpen} onHide={handleModalClose} size="lg" centered className="incidentReportDashboardModal">
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="incidentReportDashboardModalBody">
            <IncidentReport data={modalData} showIncidentReport={false} showIncidentReportColumns={false} showPagination={showPagination} enablePagination={pendingIncidents}/>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </LoadingOverlay>
    </div>
  );
  //#endregion  
}
export default IncidentReportDashboard;