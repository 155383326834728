import React, { useState, useEffect } from "react";
import helper from "../../helpers/helpers";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MaterialReactTable } from "material-react-table";
import { TextField } from "@mui/material";
import { Button } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import ModernDatepicker from "react-modern-datepicker";
import "../IncidentReport/ActionOnIncident.scss";

toast.configure();

function ActionOnIncident() {
  const [spinnerMessage] = useState("");
  const [loading] = useState(false);
  const [incidentNo] = useState("INC12345");
  const [incidentType] = useState("Network");
  const [incidentDerpartment] = useState("Admin");
  const [actionDate, setActionDate] = useState("");
  const [incidentReportColumns, setIncidentTypeColumns] = useState([]);
  const [actionOnIncidentReportData, setIncidentTypeData] = useState([]);
  const [activeRowId] = useState(null);
  const [plannedStartDate, setPlannedStartDate] = useState("12-Feb-2025");

  //#region Get Selected From Date
  const onChangePlannedStartDate = (date) => {
    setPlannedStartDate(date);
    if (date !== "" && date !== null) {
      
    }
  };
  //#endregion
  //#region Get Selected From Date
  const onChangeSetActionDate = (date) => {
    setActionDate(date);
    if (date !== "" && date !== null) {
      
    }
  };
  //#endregion
  //#region Incident Type Table Data
  const incidentTypeTableData = [
    {
      FieldName: "Name of the Person Reporting Incident",
      FieldValue: "Manoj",
    },
    {
      FieldName: "Contact No.",
      FieldValue: "123456789",
    },
    {
      FieldName: "Email",
      FieldValue: "test1234@gmail.com",
    },
    {
      FieldName: "Time of Incident",
      FieldValue: "3.30 PM",
    },
    {
      FieldName: "Location of Incident",
      FieldValue: "Office",
    },
    {
      FieldName: "Information Affected",
      FieldValue: "Office",
    },
    {
      FieldName: "Equipment Affected",
      FieldValue: "Office",
    },
    {
      FieldName: "Number of People affected",
      FieldValue: "0",
    },

    {
      FieldName: "Impact on business",
      FieldValue: "Low",
    },
    {
      FieldName: "Priority",
      FieldValue: "Medium",
    },
    {
      FieldName: "Departments Affected",
      FieldValue: "Office",
    },
    {
      FieldName: "Asset IDs",
      FieldValue: "1234",
    },
  ];
  //#endregion

  //#region Define table columns and data
  const IncidentReportTable = () => {
    const incidentReportColumns = [
      {
        accessorKey: "FieldName",
        header: "Field Name",
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
          sx: {
            width: { xs: "250px", sm: "350px", md: "400px" },
            whiteSpace: "nowrap",
          },
        },
        muiTableBodyCellProps: {
          align: "left",
          sx: {
            width: { xs: "250px", sm: "350px", md: "400px" },
            whiteSpace: "nowrap",
          },
        },
      },
      {
        accessorKey: "FieldValue",
        header: "Field Value",
        enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
          sx: {
            width: { xs: "500px", sm: "750px", md: "1150px" },
          },
        },
        muiTableBodyCellProps: {
          align: "center",
          sx: {
            width: { xs: "500px", sm: "750px", md: "1150px" },
          },
        },
      }
    ];
    return { incidentReportColumns, incidentTypeTableData };
  };
  //#endregion

  //#region Initialize table columns and data on mount
  useEffect(() => {
    const { incidentReportColumns, incidentTypeTableData } = IncidentReportTable();
    setIncidentTypeColumns(incidentReportColumns);
    setIncidentTypeData(incidentTypeTableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion

  //#region return
  return (
    <div>
      <LoadingOverlay
        active={loading}
        className="custom-loader"
        spinner={
          <div className="spinner-background">
            <BarLoader css={helper.getcss()} color={"#38D643"} width={"350px"} height={"10px"} speedMultiplier={0.3}/>
            <p style={{ color: "black", marginTop: "5px" }}>{spinnerMessage}</p>
          </div>
        }
      >
        <div className="row mg-r-15 mg-l-10 mg-t-10">
          <div className="col-lg-3">
            <div className="createProjectFloatingInput">
              <FloatingLabel label="Incident No." className="float-hidden float-select">
                <input type="text" className="form-control mg-l-5 mg-r-0" maxLength="20" value={incidentNo || ''} readOnly/>
              </FloatingLabel>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="createProjectFloatingInput actionOnIncidentDate reportIncidentDate">
              <FloatingLabel
                label={
                  <>
                    Incident Date
                  </>
                }
                className="float-hidden float-select">
                <div className="form-control" >
                  <ModernDatepicker
                    date={plannedStartDate}
                    format={"DD-MMM-YYYY"}
                    onChange={(date) =>
                      onChangePlannedStartDate(date)
                    }
                    className="color"
                    minDate={new Date(1900, 1, 1)}
                    
                  />
                </div>
              </FloatingLabel>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="createProjectFloatingInput">
              <FloatingLabel label="Incident Type" className="float-hidden float-select">
                <input type="text" className="form-control mg-l-5 mg-r-0" maxLength="20" value={incidentType || ''} readOnly/>
              </FloatingLabel>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="createProjectFloatingInput">
              <FloatingLabel label="Department resolving incident" className="float-hidden float-select">
                <input type="text" className="form-control mg-l-5 mg-r-0" maxLength="20" value={incidentDerpartment || ''} readOnly/>
              </FloatingLabel>
            </div>
          </div>
        </div>
        <div style={{ border: "1px solid #cdd4e0" }} className="mg-l-25 mg-r-20 mg-t-15 mg-b-15 actionOnIncidentReportMainText">
          <div className="row mg-r-0 mg-l-0 mg-t-15 mg-b-15">
            <div className="col-lg-5">
              <div className="createProjectFloatingInput mg-b-10">
                <FloatingLabel label="Description" className="float-hidden float-select">
                  <TextField className="resizable-textfield actionOnIncidentReportInputData actionTextAreaRead" id="Details" inputProps={{ maxLength: 4000 }}
                    multiline
                    rows={3}
                    col={300}
                    variant="outlined"
                    size="small"
                    style={{ width: "100%", height: "80px", backgroundColor: "#e3e7ed" }}
                    disabled
                  />
                </FloatingLabel>
              </div>
              <div className="masters-material-table actionOnIncidentReportTableContent">
                <MaterialReactTable
                  data={actionOnIncidentReportData}
                  columns={incidentReportColumns}
                  muiPaginationProps={{
                    color: "primary",
                    shape: "rounded",
                    showRowsPerPage: false,
                    variant: "outlined",
                    sx: {
                      "& .MuiPaginationItem-root": {
                        borderColor: "#5B47FB",
                      },
                      "& .Mui-selected": {
                        backgroundColor: "#5B47FB",
                        color: "white",
                      },
                      "& .MuiPaginationItem-ellipsis": {
                        borderColor: "#5B47FB",
                      },
                      marginTop: "16px",
                    },
                  }}
                  paginationDisplayMode="false"
                  enableColumnFilterModes={false}
                  enableColumnOrdering={false}
                  enableColumnFilters={false}
                  enableColumnActions={false}
                  enableStickyHeader={true}
                  enableDensityToggle={false}
                  enableGlobalFilter={false}
                  enableRowSelection={false}
                  enableFullScreenToggle={false}
                  initialState={{pagination: { pageIndex: 0, pageSize: 100 },}}
                  getRowProps={(row) => ({style: {backgroundColor: activeRowId === row.original.id ? "#e0e0e0" : "transparent"},})}
                />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="actionOnIncidentReportEditData mg-t-0">
                <FloatingLabel label="Root Cause" className="float-hidden float-select">
                  <TextField className="resizable-textfield actionOnIncidentReportInputData" id="Details" inputProps={{ maxLength: 4000 }}
                    multiline
                    rows={3}
                    col={300}
                    variant="outlined"
                    size="small"
                    style={{ width: "100%", height: "80px" }}
                  />
                </FloatingLabel>
                <span className="text-danger asterisk-size ml-2">*</span>
              </div>
              <div className="actionOnIncidentReportEditData mg-t-15">
                <FloatingLabel label="Corrective Action" className="float-hidden float-select">
                  <TextField className="resizable-textfield actionOnIncidentReportInputData" id="Details" inputProps={{ maxLength: 4000 }}
                    multiline
                    rows={3}
                    col={300}
                    variant="outlined"
                    size="small"
                    style={{ width: "100%", height: "80px" }}
                  />
                </FloatingLabel>
                <span className="text-danger asterisk-size ml-2">*</span>

              </div>
              <div className="actionOnIncidentReportEditData mg-t-15">
                <FloatingLabel label="Preventive Action" className="float-hidden float-select">
                  <TextField className="resizable-textfield actionOnIncidentReportInputData" id="Details" inputProps={{ maxLength: 4000 }}
                    multiline
                    rows={3}
                    col={300}
                    variant="outlined"
                    size="small"
                    style={{ width: "100%", height: "80px" }}
                  />
                </FloatingLabel>
                <span className="text-danger asterisk-size ml-2">*</span>
              </div>
              <div className="actionOnIncidentReportIncidentSelectText mg-t-15">
                <FloatingLabel label="Incident Status" className="float-hidden float-select">
                  <select className="form-control" id="version" name="version">
                    <option value="">---Select incident status---</option>
                    <option value="pending">Pending</option>
                    <option value="inprogress">InProgress</option>
                    <option value="completed">Completed</option>
                  </select>
                </FloatingLabel>
                <span className="text-danger asterisk-size ml-2">*</span>
              </div>
              <div className="actionOnIncidentReportIncidentSelectText mg-t-15">
                <FloatingLabel label="Action Completed By" className="float-hidden float-select">
                  <input type="text" className="form-control mg-l-5 mg-r-0" maxLength="20" />
                </FloatingLabel>
                <span className="text-danger asterisk-size ml-2">*</span>
              </div>
              <div className="actionOnIncidentReportAreaInputText mg-t-15 reportIncidentDate">
                <FloatingLabel
                  label={
                    <>
                      Action Completed On
                    </>
                  }
                  className="float-hidden float-select">
                  <div className="form-control" >
                    <ModernDatepicker
                      date={actionDate}
                      format={"DD-MMM-YYYY"}
                      onChange={(date) =>
                        onChangeSetActionDate(date)
                      }
                      className="color"
                      minDate={new Date(1900, 1, 1)}
                      
                    />
                  </div>
                </FloatingLabel>
                <span className="text-danger asterisk-size ml-3"></span>
              </div>
              <div className="actionOnIncidentReportIncidentSelectText mg-t-15">
                <FloatingLabel label="Remarks" className="float-hidden float-select">
                  <TextField className="resizable-textfield actionOnIncidentReportInputData" id="Details" 
                    inputProps={{ maxLength: 4000 }}
                    multiline
                    rows={3}
                    col={300}
                    variant="outlined"
                    size="small"
                    style={{ width: "100%", height: "80px" }}
                  />
                </FloatingLabel>
                <span className="text-danger asterisk-size ml-2">*</span>
              </div>
            </div>
          </div>
          <div className="actionOnIncidentReportIncidentButtonsContent mg-t-20 mg-b-20">
            <Button variant="secondary" className="actionOnIncidentReportIncidentBtn">Submit</Button>
            <Button variant="secondary" className="actionOnIncidentReportIncidentBtn">Confirm and Email</Button>
            <Button variant="secondary" className="actionOnIncidentReportIncidentBtn">Reset</Button>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
  //#endregion
}
export default ActionOnIncident;