import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import helper from "../../helpers/helpers";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./IncidentReportNew.scss";
import IncidentType from "./IncidentType";
import RegisterIncident from "./RegisterIncident";
import ActionOnIncident from "./ActionOnIncident";
import IncidentReport from "./IncidentReport";
import IncidentReportDashboard from "./IncidentReportDashboard";

toast.configure();

export default function IncidentReportNew(props) {
  const [activeTab, setActiveTab] = useState("incidentReportDashboard");
  const [loading] = useState(false);
  const [spinnerMessage] = useState("");

  //#region Tab toggle
  const toggle = (tab) => {
    setActiveTab(tab);
  };
  //#endregion

  //#region return
  return (
    <div>
      <LoadingOverlay
        active={loading}
        className="custom-loader"
        spinner={
          <div className="spinner-background">
            <BarLoader css={helper.getcss()} color={"#38D643"} width={"350px"} height={"10px"} speedMultiplier={0.3} />
            <p style={{ color: "black", marginTop: "5px" }}>{spinnerMessage}</p>
          </div>
        }
      >
        <div>
          <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={toggle}>
            <div className="row" style={{ marginRight: "15px", marginTop: '0px' }}>
              <div>
                <Nav variant="pills" className="mg-l-40 mg-b-10 mg-t-10" style={{ cursor: "pointer" }}>
                  <Nav.Item>
                    <Nav.Link eventKey="incidentReportDashboard" style={{ border: "1px solid #5E41FC" }}>
                      Incident Report Dashboard
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="registerIncident" style={{ border: "1px solid #5E41FC" }}>
                      Register Incident
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="incidentReport" style={{ border: "1px solid #5E41FC" }}>
                      Incident Report
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="actionOnIncident" style={{ border: "1px solid #5E41FC" }}>
                      Action On Incident
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="incidentType" style={{ border: "1px solid #5E41FC" }}>
                      Incident Type
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="d-flex justify-content-end"></div>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="incidentReportDashboard">
                <IncidentReportDashboard />
              </Tab.Pane>
              <Tab.Pane eventKey="registerIncident">
                <RegisterIncident />
              </Tab.Pane>
              <Tab.Pane eventKey="incidentReport">
                <IncidentReport />
              </Tab.Pane>
              <Tab.Pane eventKey="actionOnIncident">
                <ActionOnIncident />
              </Tab.Pane>
              <Tab.Pane eventKey="incidentType">
                <IncidentType {...props} />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </LoadingOverlay>
    </div>
  );
  //#endregion
};
