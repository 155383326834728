import React, { useState } from "react";
import { Row, Button } from "react-bootstrap";
import helper from "../../helpers/helpers";
import BarLoader from "react-spinners/BarLoader";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { TextField } from "@mui/material";
import "../IncidentReport/RegisterIncident.scss";
import { MultiSelect } from 'react-multi-select-component';

toast.configure();

function RegisterIncident({ showButtons = true }) {
  const [spinnerMessage] = useState("");
  const [loading] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  
  const departmentOptions = [
    { label: "Finance", value: "finance" },
    { label: "HR", value: "hr" },
    { label: "IT", value: "it" },
    { label: "Marketing", value: "marketing" },
    { label: "Operations", value: "operations" },
  ];

  //#region Return
  return (
    <div>
      <LoadingOverlay
        active={loading}
        className="custom-loader"
        spinner={
          <div className="spinner-background">
            <BarLoader css={helper.getcss()} color={"#38D643"} width={"350px"} height={"10px"} speedMultiplier={0.3} />
            <p style={{ color: "black", marginTop: "5px" }}>{spinnerMessage}</p>
          </div>
        }
      >
        <Row className="mg-t-0 mg-l-25 mg-r-30 reportIncidentData" style={{ border: "2px solid #ccc" }}>
          <div className="col-md-12 pd-t-0 pd-b-0">
            <div className="reportIncidentMainContent">
              <div className="reportIncidentContent mg-r-20 reportIncidentLeftText">
                <div className="reportIncidentSelectText mg-t-15">
                  <FloatingLabel label="Incident Type" className="float-hidden float-select">
                    <select className="form-control" id="version" name="version">
                      <option value="">---Select incident type---</option>
                      <option value="network">Network</option>
                      <option value="virus">Virus</option>
                    </select>
                  </FloatingLabel>
                  <span className="text-danger asterisk-size ml-2">*</span>
                </div>
                <div className="reportIncidentSelectText mg-t-15">
                  <FloatingLabel label="Department resolving incident" className="float-hidden float-select">
                    <select className="form-control" id="version" name="version">
                      <option value="">---Select department resolving incident---</option>
                      <option value="system">System</option>
                      <option value="admin">Admin</option>
                    </select>
                  </FloatingLabel>
                  <span className="text-danger asterisk-size ml-2">*</span>
                </div>
                <div className="reportIncidentText mg-t-15">
                  <FloatingLabel label="Description of Incident" className="float-hidden float-select">
                    <TextField className="resizable-textfield" id="Details" inputProps={{ maxLength: 4000 }}
                      multiline
                      rows={3}
                      col={300}
                      variant="outlined"
                      size="small"
                      style={{ width: "100%" }}
                    />
                  </FloatingLabel>
                  <span className="text-danger asterisk-size ml-2">*</span>
                </div>
                <div className="reportIncidentInputText mg-t-15">
                  <FloatingLabel label="Name of the Person Reporting Incident" className="float-hidden float-select">
                    <input type="text" className="form-control mg-l-5 mg-r-15" maxLength="50" name="Incident" />
                  </FloatingLabel>
                  <span className="text-danger asterisk-size ml-2">*</span>
                </div>
                <div className="reportIncidentInputText mg-t-15">
                  <FloatingLabel label="Contact No." className="float-hidden float-select">
                    <input type="number" className="form-control mg-l-5 mg-r-15" maxLength="10" name="Incident" />
                  </FloatingLabel>
                  <span className="text-danger asterisk-size ml-3"></span>
                </div>
                <div className="reportIncidentInputText mg-t-15">
                  <FloatingLabel label="Email" className="float-hidden float-select">
                    <input type="email" className="form-control mg-l-5 mg-r-15" maxLength="50" name="Incident" />
                  </FloatingLabel>
                  <span className="text-danger asterisk-size ml-3"></span>
                </div>
                <div className="reportIncidentTimeText mg-t-15 mg-b-10">
                  <TextField id="time" label="Time of Incident" type="time" InputLabelProps={{ shrink: true }}
                    inputProps={{
                      step: 300,
                    }}
                    style={{ height: "42px" }}
                  />
                  <span className="text-danger asterisk-size ml-2">*</span>
                </div>
              </div>
              <div className="reportIncidentContent">
                <div className="reportIncidentInputText mg-t-15">
                  <FloatingLabel label="Location of Incident" className="float-hidden float-select">
                    <input type="text" className="form-control mg-l-5 mg-r-15" maxLength="50" name="Incident" />
                  </FloatingLabel>
                  <span className="text-danger asterisk-size ml-2">*</span>
                </div>
                <div className="reportIncidentInputText mg-t-15">
                  <FloatingLabel label="Information Affected" className="float-hidden float-select">
                    <input type="text" className="form-control mg-l-5 mg-r-15" maxLength="50" name="Incident" />
                  </FloatingLabel>
                  <span className="text-danger asterisk-size ml-3"></span>
                </div>
                <div className="reportIncidentInputText mg-t-15">
                  <FloatingLabel label="Equipment Affected" className="float-hidden float-select">
                    <input type="text" className="form-control mg-l-5 mg-r-15" maxLength="50" name="Incident" />
                  </FloatingLabel>
                  <span className="text-danger asterisk-size ml-3"></span>
                </div>
                <div className="reportIncidentInputText mg-t-15">
                  <FloatingLabel label="Number of People affected" className="float-hidden float-select">
                    <input type="number" className="form-control mg-l-5 mg-r-15" name="Incident" />
                  </FloatingLabel>
                  <span className="text-danger asterisk-size ml-3"></span>
                </div>
                <div className="reportIncidentInputText mg-t-15">
                  <FloatingLabel label="Impact on business" className="float-hidden float-select">
                    <select className="form-control" id="version" name="version">
                      <option value="">---Select business---</option>
                      <option value="low">Low</option>
                      <option value="medium">Medium</option>
                      <option value="high">High</option>
                    </select>
                  </FloatingLabel>
                  <span className="text-danger asterisk-size ml-2">*</span>
                </div>
                <div className="reportIncidentInputText mg-t-15">
                  <FloatingLabel label="Priority" className="float-hidden float-select">
                    <select className="form-control" id="version" name="version">
                      <option>---Select priority---</option>
                      <option value="low">Low</option>
                      <option value="medium">Medium</option>
                      <option value="high">High</option>
                    </select>
                  </FloatingLabel>
                  <span className="text-danger asterisk-size ml-2">*</span>
                </div>
                <div className="reportIncidentInputText mg-t-15">
                  <FloatingLabel label="Departments Affected" className="float-hidden float-select">
                    <div className="floatStatus">
                      <MultiSelect
                        options={departmentOptions}
                        value={selectedDepartment}
                        onChange={setSelectedDepartment}
                        labelledBy="Select"
                        hasSelectAll={true}
                        disableSearch={true}
                        overrideStrings={{ selectSomeItems: "---Select Departments---", allItemsAreSelected: "All Departments Selected" }}
                        className="custom-multiselect"
                      />
                    </div>
                  </FloatingLabel>
                  <span className="text-danger asterisk-size ml-2">*</span>
                </div>

                <div className="reportIncidentInputText mg-t-15">
                  <FloatingLabel label="Asset IDs" className="float-hidden float-select">
                    <input type="text" className="form-control mg-l-5 mg-r-15" maxLength="50" name="Incident" />
                  </FloatingLabel>
                  <span className="text-danger asterisk-size ml-2">*</span>
                </div>
              </div>
            </div>
          </div>
        </Row>
        {showButtons && (
          <div className="reportIncidentButtonsContent mg-t-20">
            <Button variant="secondary" className="reportIncidentBtn">Submit</Button>
            <Button variant="secondary" className="reportIncidentBtn">Confirm and Email</Button>
            <Button variant="secondary" className="reportIncidentBtn">Reset</Button>
          </div>
        )}
      </LoadingOverlay>
    </div>
  );
  //#endregion
};
export default RegisterIncident;