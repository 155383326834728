import React, { useState, useEffect } from "react";
import helpers from "../../helpers/helpers";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingOverlay from "react-loading-overlay";
import { BarLoader } from "react-spinners";
import FloatingLabel from "react-bootstrap/FloatingLabel";

toast.configure();

export default function AddIncidentType() {
  let history = useHistory();

  // State initialization
  const [initialState, setInitStates] = useState({
    activityID: 0,
    activity: "",
    isActive: true,
    isInActive: false,
    formErrors: "",
    loading: false,
    spinnerMessage: "",
  });

  //#region Handle Activity Change
  const onChangeActivity = (e) => {
    setInitStates((prevState) => ({
      ...prevState,
      activity: e.target.value,
      formErrors: "",
    }));
  };
  //#endregion

  //#region Handle isActive Change
  const onChangeIsActive = (e) => {
    setInitStates((prevState) => ({
      ...prevState,
      isActive: e.target.checked,
    }));
  };
  //#endregion

  //#region Reset the page
  const reset = () => {
    setInitStates({
      activityID: 0,
      activity: "",
      isActive: true,
      formErrors: "",
      loading: false,
      spinnerMessage: "",
    });
  };
  //#endregion

  

  useEffect(() => {
    if (!initialState || Object.keys(initialState).length === 0) {
      history.push("/IncidentType");
    }
  }, [initialState, history]);

  //#region return
  return (
    <div className="pro-main-display">
      <LoadingOverlay
        active={initialState.loading}
        className="custom-loader"
        spinner={
          <div className="spinner-background">
            <BarLoader css={helpers.getcss()} color={"#38D643"} width={"350px"} height={"10px"} speedMultiplier={0.3}/>
            <p style={{ color: "black", marginTop: "5px" }}>{initialState.spinnerMessage}</p>
          </div>
        }
      >
        <div className="addIncidentTypeMainContent" style={{ height: "100%", position: "relative", paddingLeft: "20px", marginTop: "30px" }}>
          <h4>
            Create Incident Type{" "}
            <span className="icon-size">
              <i className="far fa-arrow-alt-circle-left text-primary pointer" onClick={() => history.goBack()} title="Back to List"></i>
            </span>
          </h4>
          <div>
            <div className="row row-sm mg-t-20">
              <div className="col-lg-4">
                <div className="createnm mroDictionayViewrVersionSelected">
                  <FloatingLabel label="Incident Type" className="float-hidden float-select">
                    <input type="text" className="form-control mg-l-5 mg-r-15 synonymInputText" maxLength="50" 
                      name="Incident"
                      value={initialState.activity}
                      onChange={onChangeActivity}
                      style={{ background: "#fff;" }}
                    />
                  </FloatingLabel>
                  <span className="text-danger asterisk-size ml-2">*</span>
                </div>
              </div>
            </div>
            <div className="row row-sm mg-t-20">
              <div className="col-md-2">
                <label><b>Is Active?</b></label>
              </div>
              <div className="col-md-5 mg-t-5">
                <label className="switch">
                  <input type="checkbox" name="IsToIncludeVendorNameInShortDesc" checked={initialState.isActive} id="chkVendorNameToShort" onChange={onChangeIsActive}/>
                  <span className="slider"></span>
                </label>
              </div>
            </div>
            <br />
            <div className="row row-sm">
              <div className="col-md-2">
                <button id="Save" className="btn btn-gray-700 btn-block" tabIndex="4" >
                  <i className="fa fa-save mg-r-5"></i> Save
                </button>
              </div>
              <div className="col-md-2">
                <button className="btn btn-gray-700 btn-block" tabIndex="5" onClick={reset} id="Reset">
                  <i className="fa fa-refresh mg-r-5"></i>Reset
                </button>
              </div>
            </div>
            <br />
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
  //#endregion
}

